import React from 'react';

class Window extends React.Component {
  constructor(props) {
    super(props);
    this.class = `browser is-active ${this.props.class}`;
  }

  render() {
    return (
      <div>
        <div className={this.class}>
          <div className="browser__bar">
            { this.props.name }
          </div>
          <div className="browser__content">
            { this.props.children }
          </div>
        </div>
      </div>
    )
  }
}

export default Window;