export default `import React from 'react';
import ReactDOM from 'react-dom';
import Remarkable from 'remarkable';
import Prism from 'prismjs';

class App extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      markdown: '',
      code: '',
    };
  }

  handleInput(event) {
    this.setState({
      markdown: event.target.innerText,
    });
  }

  getHighlightedMarkdown() {
    return {
      __html: Prism.highlight(this.state.markdown, Prism.languages.markdown),
    };
  }

  getRawMarkup() {
    const md = new Remarkable();
    return { __html: md.render(this.state.markdown) };
  }

  getHighlightedCode() {
    return {
      __html: Prism.highlight(this.state.code, Prism.languages.jsx),
    };
  }

  render() {
    return (
      <div className="browser-container">
        <Window name="Text Editor" class="is-markdown">
          <pre><code onInput={ this.handleInput.bind(this) } className="language-markdown" contentEditable="true" spellCheck="false">{ this.state.markdown }</code></pre>
          <div dangerouslySetInnerHTML={ this.getHighlightedMarkdown() }></div>
        </Window>
        <Window name="Browser" class="is-browser">
          <div dangerouslySetInnerHTML={ this.getRawMarkup() } />
        </Window>
        <Window name="Code-Editor" class="is-code-editor">
          <pre><code className="language-jsx" dangerouslySetInnerHTML={ this.getHighlightedCode() }></code></pre>
        </Window>
      </div>
    )
  }
}

ReactDOM.render(
  <App />,
  document.querySelector('#app')
);
`;