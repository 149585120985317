import React from 'react';
import ReactDOM from 'react-dom';
import Window from './components/Window';
import Remarkable from 'remarkable';
import Prism from 'prismjs';
import 'prismjs/components/prism-markdown';
import 'prismjs/components/prism-jsx';
import markdown from './components/markdown';
import code from './components/script';


class App extends React.Component {
  constructor(props) {
    super(props);

    const app = this;

    this.state = {
      markdown: '',
      markdownContent: markdown,
      code,
    };

    this.userClicked = false;
    this.markdownCode = React.createRef();

    let characterToShow = 1;

    function showNextCharacter() {
      const text = app.state.markdownContent;

      if (app.userClicked === false) {
        app.setState({
          markdown: text.substring(0, characterToShow),
          staticMarkdown: text.substring(0, characterToShow),
        });

        characterToShow += 1;

        if (characterToShow <= text.length) {
          setTimeout(showNextCharacter, 60);
        } else {
          app.markdownCode.current.focus();
        }
      } else {
        app.setState({
          markdown: text,
          staticMarkdown: text,
        });
      }
    }

    setTimeout(showNextCharacter, 400);
  }

  handleInput(event) {
    this.setState({
      markdown: event.target.innerText,
    });
  }

  handleClick() {
    this.userClicked = true;
  }

  handlePaste(event) {
    event.preventDefault();
    if (event.clipboardData && event.clipboardData.getData) {
      var text = event.clipboardData.getData("text/plain");
      document.execCommand("insertHTML", false, text);
    } else if (window.clipboardData && window.clipboardData.getData) {
      var text = window.clipboardData.getData("Text");
      insertTextAtCursor(text);
    }
  }

  getHighlightedMarkdown() {
    let output = Prism.highlight(this.state.markdown, Prism.languages.markdown);
    return { __html: output };
  }

  getRawMarkup() {
    const md = new Remarkable();
    const markdown = this.state.markdown.replace('https://giphy.com/go/ZTY5MWM4ODAt', 'https://react.de/assets/images/giphy.gif');
    return { __html: md.render(markdown) };
  }

  getHighlightedCode() {
    let output = Prism.highlight(this.state.code, Prism.languages.jsx);
    return { __html: output };
  }

  render() {
    return (
      <div className="browser-container">
        <Window name="Text Editor" class="is-markdown">
          <pre onClick={ this.handleClick.bind(this) }><code onInput={ this.handleInput.bind(this) } onPaste={ this.handlePaste.bind(this) } className="language-markdown" contentEditable="true" spellCheck="false" ref={ this.markdownCode }>{ this.state.staticMarkdown }</code></pre>
          <div dangerouslySetInnerHTML={ this.getHighlightedMarkdown() }></div>
        </Window>
        <Window name="Browser" class="is-browser">
          <div className="mini-website" dangerouslySetInnerHTML={ this.getRawMarkup() } />
        </Window>
        <Window name="Code-Editor" class="is-code-editor">
          <pre><code className="language-jsx" dangerouslySetInnerHTML={ this.getHighlightedCode() }></code></pre>
        </Window>
      </div>
    )
  }
}

ReactDOM.render(
  <App />,
  document.querySelector('#app')
);



function insertTextAtCursor(text) {
  var sel, range, html;
  if (window.getSelection) {
    sel = window.getSelection();
    if (sel.getRangeAt && sel.rangeCount) {
      range = sel.getRangeAt(0);
      range.deleteContents();
      range.insertNode(document.createTextNode(text));
    }
  } else if (document.selection && document.selection.createRange) {
    document.selection.createRange().text = text;
  }
}
